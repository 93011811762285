<template>
  <div>
    <md-card class="md-card-global-sales">
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <router-link
              tag="md-button"
              class="md-just-icon md-round md-primary pull-right"
              :to="{name: 'NewDepartment'}"
            >
              <md-icon>add</md-icon>
              <md-tooltip>New Department</md-tooltip>
            </router-link>

            <vue-table
              v-if="vTable.headers"
              ref="vtable"
              :values="vTable.values"
              :headers="vTable.headers"
              :actions="vTable.actions"
              :options="{
                tableClass:'table'
              }"
              @changed="getDepartments"
              @onShowDetails="onShowDetails"
              @onEdit="onEdit"
              @onDelete="onDelete"
            />
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';

export default {
  components: {
    VueTable,
  },
  props: [],
  data() {
    return {
      vTable: {
        headers: [
          {
            title: 'department_id',
            sortable: true,
          },
          {
            title: 'name',
            sortable: true,
          },
        ],
        actions: [
          {
            buttonClass: 'md-warning',
            tooltip: 'Show Details',
            callback: 'onShowDetails',
            icon: 'search',
          },
          {
            buttonClass: 'md-warning',
            tooltip: 'Edit Department',
            callback: 'onEdit',
            icon: 'edit',
          },
          {
            buttonClass: 'md-danger',
            tooltip: 'Delete Department',
            callback: 'onDelete',
            icon: 'close',
          },
        ],
        values: {},
      },
    };
  },
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    getDepartments(params) {
      this.request(this.$API.DEPARTMENTS.paginableUrl(params), 'get', null, ({ data }) => {
        this.vTable.values = data;
      });
    },
    onShowDetails(item) {
      this.fireModal({
        title: '<strong>Department Details</strong>',
        html: '<div class="text-muted lead" style="text-align: left">'
        + `<b>Id:</b> ${item.department_id}<br>`
        + `<b>Name:</b> ${item.name}<br>`
        + '</div>',
      });
    },
    onEdit(item, isMiddleClick) {
      this.openLink({ name: 'EditDepartment', params: { id: item.department_id } }, isMiddleClick);
    },
    onDelete(item) {
      this.fireConfirm(
        'Deleting Department',
        `Are you sure you want to delete this Department (${item.name})?`,
      )
        .then(() => {
          this.request(`${this.$API.DEPARTMENTS}${item.department_id}`, 'delete', {}, () => {
            this.fireSuccess('Department deleted successfully.');
            // Reset table after response
            this.$refs.vtable.init();
          });
        });
    },
  },
};
</script>

<style scoped>
</style>
